import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import Img from "gatsby-image"
import ReactRotatingText from "react-rotating-text"
import More from "../components/wave/more"
import styles from "../css/home.module.css"

const textVariants = {
    initial: { opacity: 0 },
    enter: { opacity: 1, transition: { duration: 0.5, ease: "easeOut"}, delay: 1},
    exit: {
        opacity: 0,
        transition: { duration: 0.2, ease: "easeOut" }
    }
}

const bggVariants = {
    initial: { backgroundColor: "#fff", 
    clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)" },
    enter: { backgroundColor: ["#fff", "#000", "#000"], clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", transition: { duration: 1.2, ease: "easeOut", delay: 0.8, times: [0, 0.1, 1.3] } },
    exit: {
        clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        transition: { duration: 0.2, ease: "easeOut" }
    }
}

const bggeVariants = {
    initial: {
        backgroundColor: "#fff",
        clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"
    },
    enter: { backgroundColor: ["#fff", "#000", "#000"], clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", transition: { duration: 1.2, ease: "easeOut", delay: 1.2, times: [0, 0.1, 1.3] } },
    exit: {
        clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)",
        transition: { duration: 0.2, ease: "easeOut" }
    }
}

const bgggVariants = {
    initial: { backgroundColor: "#fff", clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 0% 0%)" },
    enter: { backgroundColor: ["#fff", "#000", "#000"], clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)", transition: { duration: 0.8, ease: "easeOut", delay: 2.1, times: [0, 0.1, 0.8] } },
    exit: {
        clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",
        transition: { duration: 0.3, ease: "easeOut" }
    }
}

const waveVariants = {
    initial: { clipPath: "inset(0% 100% 0% 0%)"},
    enter: { clipPath: "inset(0% 0% 0% 0%)", transition: { duration: 1.2, ease: "easeOut", delay: 2.9, times: [0, 0.1, 1.2] } },
    exit: {
        clipPath: "inset(0% 100% 0% 0%)",
        transition: { duration: 0.3, ease: "easeOut" }
    }
}

const NonStretchedImage = props => {
    let normalizedProps = props
    if (props.fluid && props.fluid.presentationWidth) {
        normalizedProps = {
            ...props,
            style: {
                ...(props.style || {}),
                maxWidth: props.fluid.presentationWidth,
                margin: "0 auto", // Used to center the image
            },
        }
    }

    return <Img {...normalizedProps} />
}


const About = (image1) => {
    const data = useStaticQuery(query);
    const img = data.image1.childImageSharp.fluid;
    
    return (
        <motion.div
            className={styles.container}
            initial="initial"
            animate="enter"
            exit="exit"
            variants={{
                exit: { transition: {} },
                enter: {}
            }}
        >
            {/* <motion.section className={styles.hero}>
                <AnimatedTitles
                    animated_title_up="I'm a huge fan of anything"
                    animated_title_down=" that's fun and exciting"
                />
                <motion.p
                    className={styles.intro}
                    variants={textVariants}
                >
                    minus sky diving and bungle jumping
                </motion.p>
            </motion.section> */}

            <motion.section className={styles.duoTone}>
                <motion.h1 className={styles.colorChange} variants={textVariants}>
                    <p className={styles.rotateP}>I am a</p>
                    <ReactRotatingText className={styles.rotateBig} pause="2500" eraseMode="overwrite" deletingInterval="150" cursor={false} items={['  coder.', '  designer.', '  creator.', '  hiker.', ]} />
                </motion.h1>
                <motion.div className={styles.halfLeft} variants={bggVariants}>  
                </motion.div>

                <motion.div className={styles.halfRight} variants={bggeVariants}></motion.div>
                
                {/* <motion.div className={styles.halfRight} variants={bgggVariants}>
                   
                </motion.div> */}
                
            </motion.section>

            <motion.section className={styles.space} variants={textVariants}>
                
                <motion.h2 >
                    About  
                </motion.h2>

                {/* <motion.h3>
                   Work experience
                </motion.h3> */}

                <motion.p>
                    I went to BCIT for the New Media and Web Development program to fulfill my passion for being a web designer.  After graduation I was employed at a branding company as a web designer to help local businesses get their online presence up and running.
                </motion.p>

                <motion.p>
                    As the company diversified and launched a division in digital marketing, it was then that I had the opportunity to work on online platforms that have an impact on the everyday lives of people.
                </motion.p>

                {/* <motion.p>
                    I am using Google's ecosystem to combine SEO, performance and advertising to help brands improve their digital presence in a holistic way, including better ratings on Google's Page Speed Insight, use Google's full feature set of analytics to provide insight into business objectives and manage Google's AdWords campaigns on behalf of the client. 
                </motion.p> */}

                <motion.p>
                    Having taken a year off work and welcomed a new member to my family, right now I'm looking for the next exciting opportunity.
                </motion.p>

                {/* <NonStretchedImage fluid={img} /> */}

                {/* <motion.div className={styles.backToHome} variants={textVariants}  >
                    <Link to="/"> ⟵ Back to home</Link>
                </motion.div> */}

            </motion.section>
        </motion.div>
    )
}
export const getImage = graphql`
  fragment squareImage on File {
    childImageSharp {
      fluid(quality: 100, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "flower.jpg" }) {
      ...squareImage
    }
  }
`

export default About


